import axios, { AxiosInstance } from "axios";
import { ApiException, isApiError, isLaravelError } from "src/models/apiError";

class Api {
  client: AxiosInstance

  constructor() {
    const client = axios.create({
      baseURL: window.config.API_BASE_URL,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
      },
      withCredentials: false,
      xsrfHeaderName: 'X-XSRF-TOKEN',
    })

    client.interceptors.request.use((config) => {
      try {
        // Since MUI TablePagination is zero-based and Laravel is one-based,
        // increment the page number by 1 just before the request is sent
        const baseURL = new URL(config.baseURL);
        const fullPath = new URL(config.url, baseURL);

        // Get the search parameters from the URL
        const params = new URLSearchParams(fullPath.search);

        // Check for 'page' parameter and increment it
        if (params.has('page')) {
          const currentPage = parseInt(params.get('page'), 10);
          params.set('page', `${currentPage + 1}`); // Increment the page number for Laravel's one-based pagination
        }

        // Set the modified search parameters back on the URL
        fullPath.search = params.toString();

        // Reconstruct the URL, preserving the path part of baseURL if it exists
        const reconstructedPath = fullPath.pathname + fullPath.search + fullPath.hash;
        config.url = baseURL.pathname.endsWith('/') ? reconstructedPath.substring(1) : reconstructedPath;

        const value = window.localStorage.getItem('user');
        if (value) {
          const user = JSON.parse(value);
          //If auth cookie. Use it.
          if (user && user.token) {
            config.headers['Authorization'] = `Bearer ${user.token}`;
          }
        }
        if (window.config.PROJECT_NAME == "Evotrainer.test") {
          config.params = { ...config.params, XDEBUG_SESSION: 'VSCODE' }
        }
      } catch (err) { }
      return config
    }, (error) => {
      return Promise.reject(error)
    })

    client.interceptors.response.use((response) => {
      //fix laravel pagination so it works with MUI pagination
      if (response.data && typeof response.data.current_page !== 'undefined') {
        response.data.current_page = response.data.current_page - 1
      }
      return response
    }, (error) => {
      if (error?.response?.data) {
        console.error(error.response.data)
        if (isApiError(error.response.data)) {
          if (error.response.data.error) {
            return Promise.reject(new ApiException(error.response.data.error, error.response.data))
          }
          return Promise.reject(new ApiException(error.response.data.message, error.response.data))
        } else if (isLaravelError(error.response.data)) {
          return Promise.reject(new ApiException(error.response.data.message == '' ? error.response.data.exception : error.response.data.message))
        }
        return Promise.reject(new ApiException(error.response.data))
      }
      return Promise.reject(error)
    })

    this.client = client
  }
}

export default new Api()